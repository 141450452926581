import { api } from '..';
import { SettingsData } from './types';

export const settingsApi = api.enhanceEndpoints({ addTagTypes: ['Settings'] }).injectEndpoints({
  endpoints: build => ({
    getSettingsList: build.query<SettingsData, any>({
      query: () => ({
        url: `v0/settings`,
        method: 'GET',
      }),
    }),
    createSettings: build.mutation<any, any>({
      query: body => ({
        url: `v0/settings`,
        method: 'POST',
        body: body.formData,
      }),
    }),
    updateSettings: build.mutation<any, any>({
      query: body => ({
        url: `v0/settings/${body.id}`,
        method: 'PUT',
        body: body.formData,
      }),
    }),
    getChallenges: build.query<SettingsData, any>({
      query: () => ({
        url: `v0/settings/challenges?offset=0&limit=1&sort=createdAt desc`,
        method: 'GET',
      }),
    }),
    createChallenges: build.mutation<any, any>({
      query: body => ({
        url: `v0/settings/challenges`,
        method: 'POST',
        body,
      }),
    }),
    updateChallenges: build.mutation<any, any>({
      query: body => ({
        url: `v0/settings/challenges/${body.id}`,
        method: 'PUT',
        body,
      }),
    }),
    getPunches: build.query<SettingsData, any>({
      query: () => ({
        url: `v0/settings/punches?offset=0&limit=1&sort=createdAt desc`,
        method: 'GET',
      }),
    }),
    createPunches: build.mutation<any, any>({
      query: body => ({
        url: `v0/settings/punches`,
        method: 'POST',
        body,
      }),
    }),
    updatePunches: build.mutation<any, any>({
      query: body => ({
        url: `v0/settings/punches/${body.id}`,
        method: 'PUT',
        body,
      }),
    }),
    getWhatsappNumber: build.query<{ whatsappPhoneNumber: string }, any>({
      query: () => ({
        url: `v1/otp/whatsapp-number`,
        method: 'GET',
      }),
    }),
    updateWhatsappNumber: build.mutation<{ whatsappPhoneNumber: string }, { phone: string }>({
      query: body => ({
        url: `v1/otp/whatsapp-number`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetSettingsListQuery,
  useCreateSettingsMutation,
  useUpdateSettingsMutation,
  useLazyGetChallengesQuery,
  useCreateChallengesMutation,
  useUpdateChallengesMutation,
  useLazyGetPunchesQuery,
  useCreatePunchesMutation,
  useUpdatePunchesMutation,
  useLazyGetWhatsappNumberQuery,
  useUpdateWhatsappNumberMutation,
} = settingsApi;
